<template>
    <div class="tabmenudemo-content">
        <div class="grid p-fluid" >
            <div class="col-12 p-2">
                <Menu :slug="slug" :active="active" />

                <div class="card -m-b-2">
                    <div class="field" v-if="pageLoading">
                        <Skeleton height="24rem" width="100%"/>
                    </div>
                    <div v-else>
                        <div class="field" v-if="pageLoading">
                            <Skeleton height="14rem" width="100%"/>
                        </div>
                        <div class="field">
                            <label for="tour_policy">Tour Policy</label>
                            <Editor
                                id="tour_policy"
                                v-model="valid$.tour_policy.$model"
                                :autoResize="true"
                                editorStyle="height: 120px"
                            >
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-font">
                                            <option selected="selected"></option>
                                            <option value="serif"></option>
                                            <option value="monospace"></option>
                                        </select>
                                        <select class="ql-size">
                                            <option value="small"></option>
                                            <option selected></option>
                                            <option value="large"></option>
                                            <option value="huge"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color">
                                                <option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                        <select class="ql-background">
                                            <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-script" value="sub"></button>
                                        <button class="ql-script" value="super"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-header" value="1"></button>
                                        <button class="ql-header" value="2"></button>
                                        <button class="ql-blockquote"></button>
                                        <button class="ql-code-block"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <button class="ql-indent" value="-1"></button>
                                        <button class="ql-indent" value="+1"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-direction ql-active" value="rtl" type="button"></button>
                                        <select class="ql-align">
                                            <option selected="selected"></option><option value="center"></option><option value="right"></option><option value="justify"></option>
                                        </select>
                                    </span>
                                </template>
                            </Editor>
                        </div>
                        <div class="field">
                            <label for="cancellation_policy">Cancellation Policy</label>
                            <Editor
                                id="cancellation_policy"
                                v-model="valid$.cancellation_policy.$model"
                                :autoResize="true"
                                editorStyle="height: 120px"
                            >
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-font">
                                            <option selected="selected"></option>
                                            <option value="serif"></option>
                                            <option value="monospace"></option>
                                        </select>
                                        <select class="ql-size">
                                            <option value="small"></option>
                                            <option selected></option>
                                            <option value="large"></option>
                                            <option value="huge"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color">
                                                <option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                        <select class="ql-background">
                                            <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-script" value="sub"></button>
                                        <button class="ql-script" value="super"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-header" value="1"></button>
                                        <button class="ql-header" value="2"></button>
                                        <button class="ql-blockquote"></button>
                                        <button class="ql-code-block"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <button class="ql-indent" value="-1"></button>
                                        <button class="ql-indent" value="+1"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-direction ql-active" value="rtl" type="button"></button>
                                        <select class="ql-align">
                                            <option selected="selected"></option><option value="center"></option><option value="right"></option><option value="justify"></option>
                                        </select>
                                    </span>
                                </template>
                            </Editor>
                        </div>
                        <div class="field">
                            <label for="children_policy">Children Policy</label>
                            <Editor
                                id="children_policy"
                                v-model="valid$.children_policy.$model"
                                :autoResize="true"
                                editorStyle="height: 120px"
                            >
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-font">
                                            <option selected="selected"></option>
                                            <option value="serif"></option>
                                            <option value="monospace"></option>
                                        </select>
                                        <select class="ql-size">
                                            <option value="small"></option>
                                            <option selected></option>
                                            <option value="large"></option>
                                            <option value="huge"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color">
                                                <option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                        <select class="ql-background">
                                            <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-script" value="sub"></button>
                                        <button class="ql-script" value="super"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-header" value="1"></button>
                                        <button class="ql-header" value="2"></button>
                                        <button class="ql-blockquote"></button>
                                        <button class="ql-code-block"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <button class="ql-indent" value="-1"></button>
                                        <button class="ql-indent" value="+1"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-direction ql-active" value="rtl" type="button"></button>
                                        <select class="ql-align">
                                            <option selected="selected"></option><option value="center"></option><option value="right"></option><option value="justify"></option>
                                        </select>
                                    </span>
                                </template>
                            </Editor>
                        </div>
                        <Divider align="left" type="dashed">
                            <b>Includes</b>
                        </Divider>
                        <div class="field" >
                            <div v-for="(item, index) in included" :key="item.index" class="p-inputgroup">
                                <InputText
                                    id="included"
                                    v-model="included[index]"
                                    placeholder="Add Includes Here"
                                    :key="index" class="mb-2"
                                    style="float: left"
                                />
                                <Button 
                                    label="Remove"
                                    @click="deleteIncluded(index)"
                                    v-if="included.length != 1"
                                    icon="pi pi-times"
                                    class="p-button p-button-danger p-button-sm mb-2"
                                    style="margin-left: 5px"
                                />
                            </div>
                            <Button
                                @click="addIncluded"
                                label="Add Includes"
                                style="width: 200px; float: right;"
                            />
                        </div>
                        <Divider align="left" type="dashed">
                            <b>Excludes</b>
                        </Divider>
                        <div class="field" >
                            <div v-for="(exclude, index) in excluded" :key="index" class="p-inputgroup">
                                <InputText id="excluded"
                                    v-model="excluded[index]"
                                    placeholder="Add Excludes Here"
                                    :key="index" class="mb-2"
                                />
                                <Button
                                    label="Remove"
                                    @click="deleteExcluded(index)"
                                    v-if="excluded.length != 1"
                                    icon="pi pi-times"
                                    class="p-button p-button-danger p-button-sm mb-2"
                                    style="margin-left: 5px"
                                />
                            </div>
                            <Button
                                @click="addExcluded"
                                label="Add Excludes"
                                style="width: 200px; float: right;"
                            />
                        </div>
                        <Divider align="left" type="dashed">
                            <b>Prerequisites</b>
                        </Divider>
                        <div class="field mb-2">
                            <div v-for="(prerequisite, index) in prerequisites" :key="index" class="p-inputgroup">
                                <InputText
                                    id="prerequisites"
                                    v-model="prerequisites[index]"
                                    placeholder="Add Prerequisites Here"
                                    :key="index" class="mb-2"
                                />
                                <Button
                                    label="Remove"
                                    @click="deletePrerequisites(index)"
                                    v-if="prerequisites.length != 1"
                                    icon="pi pi-times"
                                    class="p-button p-button-danger p-button-sm mb-2"
                                    style="margin-left: 5px"
                                />
                            </div>
                            <Button
                                @click="addPrerequisites"
                                label="Add Prerequisites"
                                style="width: 200px; float: right;"
                            />
                        </div>
                    </div>
                    <br>
                    <br>
                    <Button
                        v-if="!pageLoading"
                        label="Update Details"
                        icon="pi pi-check"
                        :loading="loading"
                        @click="updateDetails(!valid$.$invalid)"
                        class="p-button-outlined mt-2"
                        style="width: 80%; margin: auto; display: block;"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Axios from 'axios';
import store from '../../../vuex';
import useVuelidate from '@vuelidate/core';
import Menu from './TabMenu.vue';

export default {
    components: {
        Menu
    },
    setup(){
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const loading = ref(false);
        const pageLoading = ref(true);
        const tour = ref();
        const submitted = ref(false);
        const current = new Date();

        const slug = ref();
        slug.value = route.params.slug;
        const active = ref(3);

        const state = reactive({
            tour_policy: "",
            children_policy: "",
            cancellation_policy: "",
        });

        const rules = {
            tour_policy: {},
            children_policy: {},
            cancellation_policy: {},
        };

        const valid$ = useVuelidate (rules, state);
        
        const included = ref(['']);
        const excluded = ref(['']);
        const prerequisites = ref(['']);

        const getSingleTour = () =>{
            pageLoading.value = true;
            Axios
            .get("api/tour/view/"+slug.value)
            .then((res) => {
                if(res.data.response=="success") {
                    pageLoading.value = false;
                    tour.value=res.data.data;

                    if(res.data.data.tour_policy != (null || "null" || ""))state.tour_policy = res.data.data.tour_policy;
                    if(res.data.data.cancellation_policy != (null || "null" || ""))state.cancellation_policy = res.data.data.cancellation_policy;
                    if(res.data.data.children_policy != (null || "null" || ""))state.children_policy = res.data.data.children_policy;
                    if(res.data.data.included)included.value = res.data.data.included;
                    if(res.data.data.excluded)excluded.value = res.data.data.excluded;
                    if(res.data.data.prerequisites)prerequisites.value = res.data.data.prerequisites;
                }
                else {
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
        getSingleTour();

        const addIncluded =  () => {
            included.value.push('');
        };
        const deleteIncluded =  (index) => {
            included.value.splice(index, 1);
        };
        const addExcluded =  () => {
            excluded.value.push('');
        };
        const deleteExcluded =  (index) => {
            excluded.value.splice(index, 1);
        };
        const addPrerequisites =  () => {
            prerequisites.value.push('');
        };
        const deletePrerequisites =  (index) => {
            prerequisites.value.splice(index, 1);
        };

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        };

        const backToDetails = () => {
            router.replace({ name: 'details', params: {slug: slug.value}});
        };
        
        const updateDetails = (isFormValid) =>{
            submitted.value = true;
            if (!isFormValid) {
                return;
            }
            const formData1 = new FormData();

            loading.value = true;

            const agency_id = ref();
            const userData = ref();

            userData.value = store.getters.userData;
            userData.value = JSON.parse(userData.value);

            agency_id.value = userData.value.agency_id;

            if(state.tour_policy != (null || "null" || ""))formData1.append("tour_policy", state.tour_policy);
            if(state.cancellation_policy != (null || "null" || ""))formData1.append("cancellation_policy", state.cancellation_policy);
            if(state.children_policy != (null || "null" || ""))formData1.append("children_policy", state.children_policy);
            if(included.value){
                for(let i=0; i<included.value.length; i++){
                formData1.append("included[]", included.value[i]);}
            }
            if(excluded.value){
                for(let i=0; i<excluded.value.length; i++){
                formData1.append("excluded[]", excluded.value[i]);}
            }
            if(prerequisites.value){
                for(let i=0; i<prerequisites.value.length; i++){
                formData1.append("prerequisites[]", prerequisites.value[i]);}
            }

            formData1.append("agency_id", agency_id.value);
            formData1.append("_method", "PUT");

            Axios
            .post(`api/operator/tour/update-details/${tour.value.id}`, formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then((res) => {
                loading.value = false;
                if(res.data.response == "success") {
                    showToast("success", "Success", res.data.message);
                    slug.value = res.data.data.slug;
                    getSingleTour();
                }
                else{
                    loading.value = false;
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                loading.value = false;
                console.log(err);
                showToast("error", "Error", "Something went wrong. Try again!");
            });

        };

        return{
            valid$,
            submitted,
            current,
            
            tour,
            loading,
            pageLoading,
            
            included,
            excluded,
            prerequisites,
            slug,
            active,

            addIncluded,
            addExcluded,
            addPrerequisites,
            deleteIncluded,
            deleteExcluded,
            deletePrerequisites,
            updateDetails,
            backToDetails,
            state,
        }
    }
}
</script>

<style scoped>
    .card{
        margin-bottom: 0px !important;
    }
</style>